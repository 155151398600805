<template>
  <div class="supplier-oauth d-flex justify-center align-center">
    <forgotPwdRetailer @toggleLogin="toggleLogin" v-if="forgotPass" />

    <div v-else class="auth-box">
      <div class="authpage-header">
        <div class="mb-5 title">
          <img
            src="@/assets/img/logo-header.svg"
            alt="Wegang"
            class="auth-header-logo"
            width="200"
          />
        </div>
        <h1 v-if="isSignIn" class="title for-retailer">
          Connect to your VGANG Droppshiper account
        </h1>
        <h1 v-else class="title for-retailer">
          Create Your Account & Start for Free
        </h1>
      </div>

      <div class="errorMessage-container" v-if="showAuthError">
        <div class="material-icons error-icon">cancel</div>
        <div>
          Ops! something happened
          <span>{{ errorMessage }}</span>
        </div>
      </div>
      <div class="successMessage-container" v-if="showAuthSucces">
        <div class="material-icons success-icon">check</div>
        <div>
          Succeess
          <span>{{ successMessage }}</span>
        </div>
      </div>
      <div v-if="isSignIn" class="form-body">
        <div class="form">
          <CustomInput
            type="email"
            id="email"
            icon="email"
            label="Email address"
            v-model="login.email"
            :required="true"
          />
          <CustomPasswordInput
            type="password"
            id="password"
            icon="key"
            label="Password"
            v-model="login.password"
          />
          <div class="term-condition title text-center my-2 mb-5">
            By connecting your connecting your account you agree to the<span>
              <a
                href="https://vgang.io/terms"
                target="_blank"
                rel="noopener noreferrer"
                style="color: #008060 !important; font-weight: bold"
              >
                term and condition
              </a>
            </span>
          </div>
          <button
            class="vgang-button form-button Polaris-Button Polaris-Button--primary"
            @click.prevent="UserSignIn"
          >
            Sign in
          </button>
        </div>
      </div>
      <div v-else class="form-body">
        <div class="form">
          <CustomInput
            type="email"
            id="email"
            icon="email"
            label="Email address"
            v-model="register.email"
            :required="true"
          />
          <CustomPasswordInput
            type="password"
            id="password"
            icon="key"
            label="Password"
            v-model="register.password"
          />
          <div class="term-condition title text-center my-2 mb-5">
            By signing up your connecting your account you agree to the<span>
              <a
                href="https://vgang.io/terms"
                target="_blank"
                rel="noopener noreferrer"
                style="color: #008060 !important; font-weight: bold"
              >
                term and condition
              </a>
            </span>
          </div>
          <button
            primary
            class="vgang-button form-button user-signUp Polaris-Button Polaris-Button--primary"
            @click.prevent="UserSignUp"
          >
            Create Your Account
          </button>

          <!-- <span class="forgot-pass">Forgot Password?</span> -->
        </div>
      </div>

      <div v-if="isSignIn" class="term-condition title text-center my-2 mb-5">
        <div>
          Don't have an account ?
          <span id="sign-up" @click="GoToSignUp"> Sign up</span>
        </div>
        <span @click="forgotPass = !forgotPass" class="forgot-pass"
          >Forgot Password?</span
        >
      </div>
      <div v-else class="term-condition title text-center my-2 mb-5">
        Already have an account ?

        <span id="sign-up" @click="GoToSignIn"> Sign in</span>
      </div>
    </div>
  </div>
</template>

<script>
import CustomInput from "@/components/CustomInput.vue";
import CustomPasswordInput from "@/components/CustomPasswordInput.vue";
import AuthService from "@/services/AuthenticationService";
// import { getVariable } from "@/utils/conditionalStoreVariables";
import { setVariable } from "@/utils/conditionalStoreVariables";
import forgotPwdRetailer from "./forgot-pwd-retailer.vue";
// Services
import { uuid } from "vue-uuid";

export default {
  data() {
    return {
      userType: "retailer",
      showAuthError: false,
      errorMessage: "",
      showAuthSucces: false,
      successMessage: "",
      uuid: uuid.v1(),
      isSignIn: true,
      accessToken: null,
      forgotPass: false,
      login: {
        email: "",
        password: "",
        action: "login",
        userType: "retailer",
        service: "vgang",
        DeviceId: uuid.v1(),
      },
      register: {
        email: "",
        password: "",
        userType: "retailer",
        DeviceId: uuid.v1(),
        action: "register",
        service: "vgang",
      },
    };
  },
  components: {
    CustomInput,
    CustomPasswordInput,
    forgotPwdRetailer,
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  beforeMount() {
    if (this.$route.query.signUp) this.GoToSignUp();
  },
  mounted() {
    const iFrameHost = process.env.VUE_APP_IFRAME_HOST;
    window.addEventListener(
      "message",
      (event) => {
        if (event.origin == iFrameHost) {
          if (event.data.type === "approve") {
            this.accessToken = event.data.data;
            if (event.data.message === "SignIn AccessToken Provided") {
              this.handleLogin(this.accessToken);
            } else if (event.data.message === "SignUp AccessToken Provided") {
              this.handleRegister(this.accessToken);
            }
          }
        } else {
          return;
        }
      },
      false
    );
  },
  methods: {
    toggleLogin() {
      this.forgotPass = !this.forgotPass;
    },
    GoToSignUp() {
      this.isSignIn = false;
    },
    GoToSignIn() {
      this.isSignIn = true;
    },
    async UserSignIn() {
      const iFrameHost = process.env.VUE_APP_IFRAME_HOST;

      let isValidEmail = this.isValidEmail(this.login.email);
      if (isValidEmail) {
        window.opener.postMessage(
          { type: "request", message: "SignIn AccessToken" },
          iFrameHost
        );
      } else {
        this.showAuthError = true;
        !this.login.email
          ? (this.errorMessage = "Please enter your email.")
          : (this.errorMessage = "Please check your email!");
        setTimeout(() => {
          this.showAuthError = false;
        }, 5000);
      }
    },
    isValidEmail(email) {
      // Regular expression for a simple email validation
      let emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

      return emailPattern.test(email);
    },
    async UserSignUp() {
      const iFrameHost = process.env.VUE_APP_IFRAME_HOST;
      let isValidEmail = this.isValidEmail(this.register.email);
      if (isValidEmail) {
        window.opener.postMessage(
          { type: "request", message: "SignUp AccessToken" },
          iFrameHost
        );
      } else {
        this.showAuthError = true;
        !this.register.email
          ? (this.errorMessage = "Please enter your email.")
          : (this.errorMessage = "Please check your email!");
        setTimeout(() => {
          this.showAuthError = false;
        }, 5000);
      }
    },
    async handleLogin(accesToken) {
      const iFrameHost = process.env.VUE_APP_IFRAME_HOST;

      try {
        //  todo send the login req here and is success send to the window.opener a message
        setVariable("role", "retailer");

        const response = await AuthService.retailerAssignUser({
          ...this.login,
          accesToken: accesToken,
          source: this.$router.history.current.query.src,
          sourceName: this.$router.history.current.query.src_name,
        });

        if (response.status !== 200) {
          this.showAuthError = true;
          this.errorMessage = "Please check your email and password";
        } else if (response.status === 200) {
          const data = await response.json();

          window.opener.postMessage(
            { type: "success", isSignUp: false, message: data.message },
            iFrameHost
          );
          this.showAuthSuccess = true;
          this.errorMessage = data.message;
          setTimeout(() => {
            window.close();
          }, 500);
        }
      } catch (error) {
        this.showAuthError = true;
        this.errorMessage = "Please try again";
      }
    },
    async handleRegister(accesToken) {
      const iFrameHost = process.env.VUE_APP_IFRAME_HOST;
      console.log("iFrameHost", iFrameHost);

      try {
        //  todo send the login req here and is success send to the window.opener a message
        setVariable("role", "retailer");

        const response = await AuthService.retailerAssignUser({
          ...this.register,
          accesToken: accesToken,
          source: this.$router.history.current.query.src,
          sourceName: this.$router.history.current.query.src_name,
        });

        if (response.status === 200) {
          const data = await response.json();

          window.opener.postMessage(
            { type: "success", isSignUp: true, message: data.message },
            iFrameHost
          );
          this.showAuthSuccess = true;
          this.successMessage = data.message;
          setTimeout(() => {
            window.close();
          }, 500);
        } else {
          this.showAuthError = true;
          this.errorMessage = "Please check your email and password";
        }
      } catch (error) {
        console.log("error", error);
        this.showAuthError = true;
        this.errorMessage = "Please try again";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.forgot-pass {
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  border-bottom: 1px dashed #008f4a;
  color: #008f4a;
  display: block;
  margin: 12px auto;
  text-align: center;
  width: 110px;
}
.auth-box {
  padding: 10px 50px !important;
}

.footer-mobile {
  display: hidden !important;
}
#sign-up {
  color: #008060 !important;
  font-weight: bold;
  &:hover {
    cursor: pointer;
  }
}
</style>
